import React from 'react';
import {
    Heading,
    PilotContainer,
    PilotImage,
    PilotNickname,
    PilotRole,
    // PilotSocialList,
    // ListItem,
} from './styled';

import { Center, Grid } from '../../../pageStyles'

const data = [
    {
        name: 'Ludovico',
        image: '/images/ludovico.jpg',
        links: {
            instagram: '',
            youtube: '',
        },
        role: 'Moderatore'
    },
    {
        name: 'SevveFPV',
        image: '/images/sf.jpg',
        links: {
            instagram: 'https://www.instagram.com/sevvefpv/',
            youtube: 'https://www.youtube.com/channel/UCi9mQSRV56vl_-vFu7Wb44w',
        },
        role: 'Blogger'
    },
    {
        name: 'DromeFPV',
        image: '/images/drome.jpeg',
        links: {
            instagram: 'https://www.instagram.com/sevvefpv/',
            youtube: 'https://www.youtube.com/channel/UCi9mQSRV56vl_-vFu7Wb44w',
        },
        role: 'Video Editor'
    },
]

export const ClubMembers = () => {
    return (
        <>
            <Center>
                <Heading>Membri del Club</Heading>
            </Center>
            <Grid>
                {data.map((pilot) =>
                    <PilotContainer>
                        {pilot.image &&
                            <PilotImage src={pilot.image}>
                                <PilotNickname>
                                    {pilot.name}
                                </PilotNickname>
                                <PilotRole>
                                    { pilot.role }
                                </PilotRole>
                                {/* 
                            <PilotSocialList>
                                <ListItem>
                                    <Link href={pilot.links.youtube} target="_blank" rel="noopener noreferrer"></Link>
                                </ListItem>
                                <ListItem>
                                    <Link href={pilot.links.instagram} target="_blank" rel="noopener noreferrer"></Link>
                                </ListItem>
                            </PilotSocialList> */}
                            </PilotImage>
                        }
                    </PilotContainer>
                )}
            </Grid>
        </>
    );
}