import styled from 'styled-components';
import { colors, fonts, media } from '../../../utils';

export const Heading = styled(fonts.Heading)`
    margin: 2rem auto;
`;

export const Grid = styled.section`
    display: grid;
    justify-content: space-between;
    grid-gap: 3rem;
    grid-template-columns: 1fr;

    ${media.medium`
        grid-template-columns: 1fr 1fr 1fr;
    `}
`;

export const Pilot = styled.div`
    color: inherit;
    text-decoration: none;
`;

export const PilotImage = styled.div`
    border-radius: 2.5rem;
    margin: 0 0 10px 0;
    height: 20rem;
    background: ${colors.black} no-repeat center center;
    background-image: url("${props => props.src}");
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.35);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const PilotName = styled(fonts.SubHeading)`
    color: ${colors.white};
`;

export const PilotStyle = styled(fonts.Body)`
    color: ${colors.white};
`;
