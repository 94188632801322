import React from 'react';

import { Layout } from '../../components/common';
import { ClubMembers } from '../../components/common/ClubMembers';
import { TelegramChannel } from '../../components/common/Promo';
import { Team } from '../../components/common/Team';
import { Container } from '../../pageStyles/index'

// Styles
import '../../styles/app.css'

const Index = () => {
    return (
        <Layout promotion={<TelegramChannel/>}>
            <Container>
                <Team />
                <ClubMembers/>
            </Container>
        </Layout>
    )
}

export default Index