import React from 'react'

import {
    Center,
} from '../../../pageStyles'

import {
    Heading,
    Grid,
    Pilot,
    PilotImage,
    PilotName,
    PilotStyle,
} from './styled'

const data = [
    {
        name: 'iamLucaFPV',
        image: '/images/lucafpv.jpg',
        style: 'Freestyle, Cinematic Pilot'
    },
    {
        name: 'Nicho Roberts',
        image: '/images/nichoroberts.jpg',
        style: 'Freestyle Pilot'
    },
    {
        name: 'Mr. Oizo',
        image: '/images/mroizo.jpeg',
        style: 'Racing, Freestyle Pilot'
    }
]

export const Team = () => (
    <>
            <Center>
                <Heading>
                    Piloti Fondatori
                </Heading>    
            </Center>
            <Grid>
                {data.map((pilot, i) => {
                    return (
                        <Pilot>
                            {pilot.image &&
                                <PilotImage src={pilot.image}>
                                    <PilotName>{pilot.name}</PilotName>
                                    {pilot.style ?
                                        <PilotStyle>{pilot.style}</PilotStyle>
                                        : null
                                    }
                                </PilotImage>}
                        </Pilot>
                    )
                })}
            </Grid>
    </>
);