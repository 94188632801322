import styled from 'styled-components';
import { colors, fonts, media } from '../../../utils';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 4vw;
`;

export const Heading = styled(fonts.Heading)`
    color: ${colors.black};
    padding: 1rem 0 0 0;
`;

export const PilotContainer = styled.div`
    text-align: center;
`;

export const PilotImage = styled.div`
    border-radius: 2rem;
    margin: 0 0 10px 0;
    height: 200px;
    background: ${colors.black} no-repeat center center;
    background-image: url("${props => props.src}");
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.35);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const PilotNickname = styled(fonts.Heading)`
    color: ${colors.white} !important;
    margin: 0;
`;

export const PilotRole = styled(fonts.Body)`
    color: ${colors.white}
`;